.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 70px;
  padding: 10px 80px;
  background-color: transparent;
  transition: background-color 0.45s ease-in-out;
  background-color: #fff;
  border-bottom: 1px solid #d5d5d5;

  @media screen and (max-width: 1200px) {
    padding: 10px 40px;
    .n-contacts-link-text {
      letter-spacing: normal !important;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 10px 20px;
  }

  .n-logo,
  .n-right {
    flex: 1;
    height: 85%;
  }

  .n-logo {
    background: url('../../../assets/logoB.png') no-repeat;
    background-size: contain;
    background-position: left top;
    @media screen and (max-width: 600px) {
      height: 100%;
      background-position: center center;
    }
    @media screen and (max-width: 300px) {
      flex: 1.4;
    }
  }
  .n-contacts {
    flex: 1.8;
    color: #000;
    perspective: 100px;
    height: 85%;

    @media screen and (max-width: 800px) {
      display: none;
    }

    .n-contacts-link {
      font-size: 20px;
      color: #000;
      transition: transform 1s;
      &:hover {
        color: #f5deb3;
        transform: translateZ(5px);
      }
      .n-contacts-link-text {
        margin-left: 10px;
      }
    }
  }

  .n-right {
    .n-right-socials {
      margin-right: 10px;

      @media screen and (max-width: 1200px) {
        display: none;
      }

      .social-icon-link {
        margin: 0 3px;
        padding: 5px;
        border-radius: 50%;
        transition: transform 0.8s;
        &:hover {
          transform: rotate(360deg) scale(1.3);
        }
        svg {
          width: 25px;
          height: 25px;
          fill: #000;
        }
      }
    }
  }
}

.underConstraction {
  margin-top: 90px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  img {
    width: 650px;
    margin: auto;
    display: block;
  }
  .underConstraction-text {
    text-align: center;
    padding: 20px 0;
    background-color: rgb(170, 170, 170);
    p {
      margin: 5px 0 20px 0;
      font-size: smaller;
    }
    .underConstraction-button {
      margin: 0 20px;
      color: black;
      padding: 10px 25px;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: rgb(100, 99, 99);
      }
    }
  }

  @media screen and (max-width: 800px) {
    img {
      width: 50%;
    }
  }
}

.impressumPage {
  margin-top: 120px;
  padding: 0 40px;
  margin-bottom: 100px;

  .impressumSubtitle {
    font-size: 25px;
    font-weight: bold;
    margin: 60px 0 25px 0;
  }
  .impressumText {
    font-size: 20px;
    margin-bottom: 15px;
    width: 600px;
    letter-spacing: 1.8px;

    &.second {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 0 20px;

    .impressumText {
      width: initial;
    }
  }
}

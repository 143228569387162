.scrollTopIcon {
    display: flex;
    position: fixed;
    right: -80px;
    bottom: 20px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &.true {
      transform: translateX(-100px);
    }
    svg {
      width: auto;
      height: 40px;
      fill: white;
    }
  
    &:hover {
      opacity: 1;
    }
  }
  
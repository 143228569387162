.button {
  min-width: 35%;
  padding: 12px 15px;
  border-radius: 20px;
  font-size: 1.2em;
  color: #fff;
  border: 2px solid #fff;
  background-color: transparent;
  transition: all 0.3s ease-in !important;
  &.filled {
    background-color: #fff;
    color: #000;
    &:hover {
      background-color: transparent;
      color: #fff;
    }
  }
  &.bordered {
    border: 2px solid #fff;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
  &.green {
    max-width: fit-content;
    min-width: fit-content;
    margin: auto;
    margin-top: 60px;
    padding: 15px 20px;
    border-radius: 10px;
    border: 2px solid rgb(2, 109, 2);
    background-color: rgb(2, 109, 2);
    &:hover {
      background-color: #fff;
      color: #000;
    }
    @media screen and (max-width: 600px) {
      margin-top: 25px;
      padding: 12px 15px;
    }
  }
}

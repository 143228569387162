.select-language {
  position: relative;
  width: 160px;
  max-width: 160px;
  height: 45px;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  letter-spacing: 2px;

  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  font-size: 18px;
  img {
    width: 40px;
    margin-right: 5px;
  }
  .arrow {
    width: 20px;
    height: fit-content;
    margin-left: auto;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
    color: white;
    transform: rotate(180deg);
    g {
      fill: white;
    }
  }
  .select-languge_dropdown {
    position: absolute;
    bottom: 55px;
    left: 0;
    width: 180px;
    overflow: hidden;
    visibility: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:hover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .arrow {
      transform: rotate(0deg);
    }
    .select-languge_dropdown {
      max-height: 55px;
      visibility: visible;
    }
  }
  .single-language {
    padding: 5px 10px;
    height: 45px;
    background-color: rgb(0, 0, 0);
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(39, 39, 39, 0.5),
        rgb(39, 39, 39)
      );
    }
  }

  @media screen and (max-width: 780px) {
    -webkit-tap-highlight-color: transparent;
    &:hover {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .select-languge_dropdown {
        max-height: 0;
        visibility: hidden;
      }
      .arrow {
        transform: rotate(0);
      }
    }
    &.true {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .arrow {
        transform: rotate(0deg);
      }
      .select-languge_dropdown {
        max-height: 55px;
        visibility: visible;
      }
    }
    &.false {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
  @media screen and (max-width: 300px) {
    width: 150px;
    max-width: 180px;
    img {
      width: 30px;
      margin-right: 3px;
    }
    .arrow {
      width: 12px;
      margin-right: 0;
    }

    .select-languge_dropdown {
      width: 170px;
    }
  }
}

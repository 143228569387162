.banner {
  position: relative;
  height: 110vh;
  padding: 0 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  // background: url('../../assets/banner.png') no-repeat center center fixed;
  // background-size: cover;

  .b-text {
    width: 80%;
    color: #fff;
    line-height: 1.6;
    font-size: 23px;
    span {
      width: 18px;
      height: 18px;
      margin-bottom: 8px;
      border-radius: 50%;
      margin-right: 20px;
      display: inline-block;
      background-color: white;
    }
    .b-buttons {
      max-width: 40%;
      margin-top: 80px;
      z-index: 2;
      font-size: initial;
    }
  }

  .b-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    path {
      fill: #fff;
    }
  }

  @media screen and(max-width: 1200px) {
    padding: 0 40px;
    background-attachment: initial;
  }

  @media screen and (max-width: 800px) {
    padding: 0 20px;
    height: 100vh;
    .b-text {
      max-width: 100%;
      line-height: normal;
      font-size: 22px;
      h1 {
        > div {
          margin-bottom: 10px;
        }
      }
      span {
        margin-right: 10px;
      }
      .b-buttons {
        max-width: 60%;
        margin-top: 60px;
      }
    }
  }

  @media screen and(max-width: 600px) {
    background-position: initial;
    .b-text {
      width: 100%;
      .b-buttons {
        max-width: 100%;
        margin-top: 70px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 350px) {
    padding-top: 30px;
    .b-text {
      h1 {
        font-size: 1.5em;
        > div {
          display: flex;
          align-items: center;
        }
        span {
          margin-top: 5px;
          width: 15px;
          height: 15px;
        }
      }
    }
    height: 90vh;
    .b-text {
      .b-buttons {
        flex-wrap: wrap;
        max-width: 100%;
        margin-top: 50px;
        .button {
          &:last-of-type {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

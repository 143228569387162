.someOfWorks {
  margin-top: 70px;
  p {
    font-size: smaller;
    text-align: center;
    margin-bottom: 5px;
  }
  .someOfWorks-imgs {
    > img {
      cursor: pointer;
      width: 23%;
      height: 300px;
      margin-bottom: 25px;
      border-radius: 5px;
    }
    #ReactSimpleImageViewer {
      z-index: 10000000;
      .styles-module_close__2I1sI,
      .styles-module_navigation__1pqAE {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        height: fit-content;
        &:hover {
          opacity: 0.5;
        }
      }
    }

    @media screen and (max-width: 800px) {
      > img {
        width: 45%;
      }
    }
    @media screen and (max-width: 600px) {
      > img {
        width: 70%;
        height: initial;
      }
    }
    @media screen and (max-width: 500px) {
      > img {
        width: 90%;
      }
    }
  }
}

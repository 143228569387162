@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}
.App {
  overflow: hidden;
}
a {
  text-decoration: none;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.space-between {
  justify-content: space-between;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}

.title {
  width: fit-content;
  margin: auto;
  font-size: 35px;
  letter-spacing: 5px;
  padding-bottom: 3px;
  border-bottom: 2.8px solid #000;
  text-align: center;
  @media screen and (max-width: 600px) {
    letter-spacing: initial;
  }
}
.subtitle {
  width: 90%;
  margin: 15px auto 50px auto;
  font-weight: normal;
  line-height: 2;

  @media screen and(max-width: 600px) {
    width: 95%;
    margin-bottom: 30px;
  }
}

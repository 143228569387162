@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
*{font-family:"Roboto",sans-serif;margin:0}.App{overflow:hidden}a{text-decoration:none}.flex{display:flex}.flex-wrap{flex-wrap:wrap}.column{flex-direction:column}.row{flex-direction:row}.space-between{justify-content:space-between}.space-evenly{justify-content:space-evenly}.space-around{justify-content:space-around}.align-center{align-items:center}.justify-center{justify-content:center}.justify-end{justify-content:flex-end}.text-center{text-align:center}.title{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin:auto;font-size:35px;letter-spacing:5px;padding-bottom:3px;border-bottom:2.8px solid #000;text-align:center}@media screen and (max-width: 600px){.title{letter-spacing:normal;letter-spacing:initial}}.subtitle{width:90%;margin:15px auto 50px auto;font-weight:normal;line-height:2}@media screen and (max-width: 600px){.subtitle{width:95%;margin-bottom:30px}}
.navbar{position:fixed;top:0;left:0;right:0;z-index:10;height:70px;padding:10px 80px;background-color:transparent;transition:background-color .45s ease-in-out;background-color:#fff;border-bottom:1px solid #d5d5d5}@media screen and (max-width: 1200px){.navbar{padding:10px 40px}.navbar .n-contacts-link-text{letter-spacing:normal !important}}@media screen and (max-width: 800px){.navbar{padding:10px 20px}}.navbar .n-logo,.navbar .n-right{flex:1 1;height:85%}.navbar .n-logo{background:url(/static/media/logoB.b2ca828c.png) no-repeat;background-size:contain;background-position:left top}@media screen and (max-width: 600px){.navbar .n-logo{height:100%;background-position:center center}}@media screen and (max-width: 300px){.navbar .n-logo{flex:1.4 1}}.navbar .n-contacts{flex:1.8 1;color:#000;perspective:100px;height:85%}@media screen and (max-width: 800px){.navbar .n-contacts{display:none}}.navbar .n-contacts .n-contacts-link{font-size:20px;color:#000;transition:transform 1s}.navbar .n-contacts .n-contacts-link:hover{color:wheat;transform:translateZ(5px)}.navbar .n-contacts .n-contacts-link .n-contacts-link-text{margin-left:10px}.navbar .n-right .n-right-socials{margin-right:10px}@media screen and (max-width: 1200px){.navbar .n-right .n-right-socials{display:none}}.navbar .n-right .n-right-socials .social-icon-link{margin:0 3px;padding:5px;border-radius:50%;transition:transform .8s}.navbar .n-right .n-right-socials .social-icon-link:hover{transform:rotate(360deg) scale(1.3)}.navbar .n-right .n-right-socials .social-icon-link svg{width:25px;height:25px;fill:#000}
.dropdown{position:fixed;top:90px;right:80px;width:20%;max-height:0;overflow:hidden;border-bottom-left-radius:10px;border-bottom-right-radius:10px;transition:max-height .4s ease;background-color:#fff;border:1px solid #d5d5d5;border-top:none}.dropdown.opened{max-height:190px}.dropdown .dropdown-link{padding:15px 20px;font-size:20px;color:#000;transition:all .2s ease-in-out}.dropdown .dropdown-link.active{background-color:#c8c8c8;color:#000}.dropdown .dropdown-link:hover{background-color:#c8c8c8;color:#000}.dropdown .dropdown-link:last-of-type{border-bottom-left-radius:10px;border-bottom-right-radius:10px}@media screen and (max-width: 1200px){.dropdown{right:40px}}@media screen and (max-width: 800px){.dropdown{right:0;left:0;width:100%}}
.banner{position:relative;height:110vh;padding:0 80px;background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/static/media/banner.0b910acf.png);background-repeat:no-repeat;background-size:cover;background-position:center center;background-attachment:fixed}.banner .b-text{width:80%;color:#fff;line-height:1.6;font-size:23px}.banner .b-text span{width:18px;height:18px;margin-bottom:8px;border-radius:50%;margin-right:20px;display:inline-block;background-color:#fff}.banner .b-text .b-buttons{max-width:40%;margin-top:80px;z-index:2;font-size:medium;font-size:initial}.banner .b-svg{position:absolute;bottom:0;left:0;right:0}.banner .b-svg path{fill:#fff}@media screen and (max-width: 1200px){.banner{padding:0 40px;background-attachment:scroll;background-attachment:initial}}@media screen and (max-width: 800px){.banner{padding:0 20px;height:100vh}.banner .b-text{max-width:100%;line-height:normal;font-size:22px}.banner .b-text h1>div{margin-bottom:10px}.banner .b-text span{margin-right:10px}.banner .b-text .b-buttons{max-width:60%;margin-top:60px}}@media screen and (max-width: 600px){.banner{background-position:0 0;background-position:initial}.banner .b-text{width:100%}.banner .b-text .b-buttons{max-width:100%;margin-top:70px}}@media screen and (max-width: 400px){.banner{padding-top:100px}}@media screen and (max-width: 350px){.banner{padding-top:30px;height:90vh}.banner .b-text h1{font-size:1.5em}.banner .b-text h1>div{display:flex;align-items:center}.banner .b-text h1 span{margin-top:5px;width:15px;height:15px}.banner .b-text .b-buttons{flex-wrap:wrap;max-width:100%;margin-top:50px}.banner .b-text .b-buttons .button:last-of-type{margin-top:20px}}
.button{min-width:35%;padding:12px 15px;border-radius:20px;font-size:1.2em;color:#fff;border:2px solid #fff;background-color:transparent;transition:all .3s ease-in !important}.button.filled{background-color:#fff;color:#000}.button.filled:hover{background-color:transparent;color:#fff}.button.bordered{border:2px solid #fff}.button.bordered:hover{background-color:#fff;color:#000}.button.green{max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content;min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content;margin:auto;margin-top:60px;padding:15px 20px;border-radius:10px;border:2px solid #026d02;background-color:#026d02}.button.green:hover{background-color:#fff;color:#000}@media screen and (max-width: 600px){.button.green{margin-top:25px;padding:12px 15px}}
.works{width:90%;margin:auto;padding:50px 0}.works .alice-carousel{width:95%}.works .alice-carousel .alice-carousel__dots{display:none}.works .alice-carousel .alice-carousel__prev-btn,.works .alice-carousel .alice-carousel__next-btn{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;display:flex;align-items:center;font-size:70px;padding:0;position:absolute;top:calc(50% - 47.5px)}.works .alice-carousel .alice-carousel__prev-btn{left:-50px}.works .alice-carousel .alice-carousel__next-btn{right:-50px}.works .alice-carousel .alice-carousel__prev-btn-item,.works .alice-carousel .alice-carousel__next-btn-item{padding:0;color:#1d3821;transition:color .3s ease-in-out}.works .alice-carousel .alice-carousel__prev-btn-item:hover,.works .alice-carousel .alice-carousel__next-btn-item:hover{color:#747474}.works .alice-carousel .alice-carousel__prev-btn-item.__inactive,.works .alice-carousel .alice-carousel__next-btn-item.__inactive{display:none}.works .alice-carousel .item{margin:auto;width:95%;height:350px;border-radius:10px;background-size:cover}@media screen and (max-width: 800px){.works .alice-carousel .alice-carousel__prev-btn{left:-45px}.works .alice-carousel .alice-carousel__next-btn{right:-45px}.works .alice-carousel .alice-carousel__dots{display:block;margin-top:20px}.works .alice-carousel .alice-carousel__dots .alice-carousel__dots-item{background-color:#747474}.works .alice-carousel .alice-carousel__dots .alice-carousel__dots-item.__active{background-color:#000}}@media screen and (max-width: 600px){.works{padding:30px 0}.works .alice-carousel .alice-carousel__prev-btn,.works .alice-carousel .alice-carousel__next-btn{font-size:50px}.works .alice-carousel .alice-carousel__prev-btn{left:-25px}.works .alice-carousel .alice-carousel__next-btn{right:-25px}}
.footer{padding:40px 100px 10px 100px;color:#fff;background-color:#0d1a0c}.footer .footer-top-row{width:40%}.footer .footer-top-row .footer-top-row-title{position:relative;font-size:30px;font-weight:500;text-transform:uppercase}.footer .footer-top-row .footer-top-row-title::before{position:absolute;left:0;bottom:-5px;content:"";width:25%;height:3px;background-color:#d24242}.footer .footer-top-row .footer-top-row-content{margin-top:40px}.footer .footer-top-row .footer-top-row-content a{height:35px;margin-bottom:15px;font-size:20px;font-weight:500;color:#ddd;transition:all .2s ease-in-out}.footer .footer-top-row .footer-top-row-content a:last-of-type{margin-bottom:0}.footer .footer-top-row .footer-top-row-content a:hover{color:#646464}.footer .footer-top-row .footer-top-row-content a:hover svg{filter:invert(0);transform:rotate(360deg)}.footer .footer-top-row .footer-top-row-content a svg{width:35px;height:auto;margin-right:10px;transition:all .3s ease-in-out;transition:transform .6s ease-in-out}.footer .footer-bottom{text-align:center;font-size:18px;margin-top:50px;perspective:100px}.footer .footer-bottom .impressum-link{color:#fff;font-size:12px;display:flex;justify-content:center;color:#fff;transition:transform 1s}.footer .footer-bottom .impressum-link:hover{color:#646464;transform:translateZ(5px)}.footer .footer-bottom hr{margin-top:5px;margin-bottom:10px}.footer .footer-bottom .select-language{margin-left:auto}@media screen and (max-width: 1024px){.footer{padding:40px 80px;padding-bottom:10px}}@media screen and (max-width: 800px){.footer{padding:40px;padding-bottom:10px}.footer .select-language{margin:auto;margin-top:20px}}@media screen and (max-width: 600px){.footer{padding:30px 30px}.footer .footer-top-row{width:100%;margin-top:40px}.footer .footer-top-row .footer-top-row-content{margin-top:20px}}
.select-language{position:relative;width:160px;max-width:160px;height:45px;padding:5px 10px;cursor:pointer;color:#fff;letter-spacing:2px;background-color:#000;border-radius:5px;font-size:18px}.select-language img{width:40px;margin-right:5px}.select-language .arrow{width:20px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin-left:auto;margin-right:5px;transition:all .3s ease-in-out;color:#fff;transform:rotate(180deg)}.select-language .arrow g{fill:#fff}.select-language .select-languge_dropdown{position:absolute;bottom:55px;left:0;width:180px;overflow:hidden;visibility:hidden;border-top-left-radius:5px;border-top-right-radius:5px}.select-language:hover{border-top-left-radius:0;border-top-right-radius:0}.select-language:hover .arrow{transform:rotate(0deg)}.select-language:hover .select-languge_dropdown{max-height:55px;visibility:visible}.select-language .single-language{padding:5px 10px;height:45px;background-color:#000}.select-language .single-language:hover{background:linear-gradient(90deg, rgba(39, 39, 39, 0.5), #272727)}@media screen and (max-width: 780px){.select-language{-webkit-tap-highlight-color:transparent}.select-language:hover{border-bottom-left-radius:5px;border-bottom-right-radius:5px}.select-language:hover .select-languge_dropdown{max-height:0;visibility:hidden}.select-language:hover .arrow{transform:rotate(0)}.select-language.true{border-bottom-left-radius:0;border-bottom-right-radius:0}.select-language.true .arrow{transform:rotate(0deg)}.select-language.true .select-languge_dropdown{max-height:55px;visibility:visible}.select-language.false .arrow{transform:rotate(180deg)}}@media screen and (max-width: 300px){.select-language{width:150px;max-width:180px}.select-language img{width:30px;margin-right:3px}.select-language .arrow{width:12px;margin-right:0}.select-language .select-languge_dropdown{width:170px}}
.prices{margin-top:50px;padding-bottom:30px}.prices .prices-text{width:40%}.prices .prices-text .title{margin:0;margin:initial;text-align:left;text-align:initial}.prices .prices-text .subtitle{text-align:left;text-align:initial;width:100%;margin-top:40px;line-height:1.4}.prices .prices-photo{text-align:center;font-size:larger;width:35%}.prices .prices-photo img{cursor:pointer}.prices .prices-photo #ReactSimpleImageViewer{z-index:10000000}.prices .prices-photo #ReactSimpleImageViewer .styles-module_close__2I1sI,.prices .prices-photo #ReactSimpleImageViewer .styles-module_navigation__1pqAE{opacity:1;transition:opacity .2s ease-in-out;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.prices .prices-photo #ReactSimpleImageViewer .styles-module_close__2I1sI:hover,.prices .prices-photo #ReactSimpleImageViewer .styles-module_navigation__1pqAE:hover{opacity:.5}.prices .prices-photo a{margin-left:10px}@media screen and (max-width: 800px){.prices{flex-direction:column;margin-top:100px}.prices .prices-text,.prices .prices-photo{width:90%}}
.someOfWorks{margin-top:70px}.someOfWorks p{font-size:smaller;text-align:center;margin-bottom:5px}.someOfWorks .someOfWorks-imgs>img{cursor:pointer;width:23%;height:300px;margin-bottom:25px;border-radius:5px}.someOfWorks .someOfWorks-imgs #ReactSimpleImageViewer{z-index:10000000}.someOfWorks .someOfWorks-imgs #ReactSimpleImageViewer .styles-module_close__2I1sI,.someOfWorks .someOfWorks-imgs #ReactSimpleImageViewer .styles-module_navigation__1pqAE{opacity:1;transition:opacity .2s ease-in-out;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.someOfWorks .someOfWorks-imgs #ReactSimpleImageViewer .styles-module_close__2I1sI:hover,.someOfWorks .someOfWorks-imgs #ReactSimpleImageViewer .styles-module_navigation__1pqAE:hover{opacity:.5}@media screen and (max-width: 800px){.someOfWorks .someOfWorks-imgs>img{width:45%}}@media screen and (max-width: 600px){.someOfWorks .someOfWorks-imgs>img{width:70%;height:auto;height:initial}}@media screen and (max-width: 500px){.someOfWorks .someOfWorks-imgs>img{width:90%}}
.contactCards-top{margin-top:130px}.contactCards-top .contactCards{padding:20px 0 80px 0}.contactCards-top .contactCards .contactCard{width:300px;height:320px;padding:0 25px;margin:20px 0;text-align:center;border-radius:15px;box-shadow:5px 5px rgba(11,98,0,.4),10px 10px rgba(11,98,90,.3),15px 15px rgba(11,98,90,.2),20px 20px rgba(11,98,90,.1),25px 25px rgba(11,98,90,.05);color:#000;transition:box-shadow .25s}.contactCards-top .contactCards .contactCard:hover{box-shadow:-5px 5px rgba(11,98,0,.4),-10px 10px rgba(11,98,90,.3),-15px 15px rgba(11,98,90,.2),-20px 20px rgba(11,98,90,.1),-25px 25px rgba(11,98,90,.05)}.contactCards-top .contactCards .contactCard .contactCard-icon svg{font-size:60px;fill:#173d13}.contactCards-top .contactCards .contactCard .contactCard-title{margin:10px 0 20px 0}.contactCards-top .contactCards .contactCard .contactCard-link{color:#000;font-size:larger;transition:transform .5s}.contactCards-top .contactCards .contactCard .contactCard-link:hover{transform:scale(1.1)}@media screen and (max-width: 600px){.contactCards-top .contactCards .contactCard{width:75%}}.contactMap{width:80%;margin:auto;margin-bottom:60px}.contactMap iframe{width:100%}@media screen and (max-width: 600px){.contactMap{width:95%;margin-bottom:50px}}
.underConstraction{margin-top:90px;max-height:100vh;display:flex;flex-direction:column}.underConstraction img{width:650px;margin:auto;display:block}.underConstraction .underConstraction-text{text-align:center;padding:20px 0;background-color:#aaa}.underConstraction .underConstraction-text p{margin:5px 0 20px 0;font-size:smaller}.underConstraction .underConstraction-text .underConstraction-button{margin:0 20px;color:#000;padding:10px 25px;font-size:18px;cursor:pointer;transition:all .3s ease-in-out}.underConstraction .underConstraction-text .underConstraction-button:hover{background-color:#646363}@media screen and (max-width: 800px){.underConstraction img{width:50%}}
.scrollTopIcon{display:flex;position:fixed;right:-80px;bottom:20px;align-items:center;justify-content:center;z-index:1000;cursor:pointer;transition:all .5s ease-in-out}.scrollTopIcon.true{transform:translateX(-100px)}.scrollTopIcon svg{width:auto;height:40px;fill:#fff}.scrollTopIcon:hover{opacity:1}
.cookies{background-color:#000;position:fixed;bottom:0;padding:30px 30px 8px 30px;left:0;right:0;color:#fff;z-index:100000 !important;height:100px}.cookies h3{font-weight:normal;z-index:100000 !important}.cookies .cookies-buttons{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-left:auto;margin-right:20px;margin-top:20px;z-index:100000 !important}.cookies .cookies-buttons .cookies-button{text-decoration:none;border:none;cursor:pointer;padding:10px 15px;margin:0 10px;font-size:18px;border-radius:5px;transition:all .3s ease-in-out;z-index:100000 !important;background-color:#fff;color:#000}.cookies .cookies-buttons .cookies-button:hover{background-color:#616161;color:#fff}@media screen and (max-width: 600px){.cookies{padding:20px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.cookies .cookies-buttons{margin-left:0;margin-left:initial}.cookies .cookies-buttons .cookies-button{margin-top:10px;padding:5px 10px}}
.impressumPage{margin-top:120px;padding:0 40px;margin-bottom:100px}.impressumPage .impressumSubtitle{font-size:25px;font-weight:bold;margin:60px 0 25px 0}.impressumPage .impressumText{font-size:20px;margin-bottom:15px;width:600px;letter-spacing:1.8px}.impressumPage .impressumText.second{margin-bottom:30px}@media screen and (max-width: 700px){.impressumPage{padding:0 20px}.impressumPage .impressumText{width:auto;width:initial}}

.contactCards-top {
  margin-top: 130px;
  .contactCards {
    padding: 20px 0 80px 0;
    .contactCard {
      width: 300px;
      height: 320px;
      padding: 0 25px;
      margin: 20px 0;
      text-align: center;
      border-radius: 15px;

      box-shadow: 5px 5px rgba(11, 98, 0, 0.4), 10px 10px rgba(11, 98, 90, 0.3),
        15px 15px rgba(11, 98, 90, 0.2), 20px 20px rgba(11, 98, 90, 0.1),
        25px 25px rgba(11, 98, 90, 0.05);

      color: black;
      transition: box-shadow 0.25s;
      &:hover {
        box-shadow: -5px 5px rgba(11, 98, 0, 0.4),
          -10px 10px rgba(11, 98, 90, 0.3), -15px 15px rgba(11, 98, 90, 0.2),
          -20px 20px rgba(11, 98, 90, 0.1), -25px 25px rgba(11, 98, 90, 0.05);
      }

      .contactCard-icon {
        svg {
          font-size: 60px;
          fill: #173d13;
        }
      }
      .contactCard-title {
        margin: 10px 0 20px 0;
      }
      .contactCard-link {
        color: #000;
        font-size: larger;
        transition: transform 0.5s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    @media screen and (max-width: 600px) {
      .contactCard {
        width: 75%;
      }
    }
  }
}

.contactMap {
  width: 80%;
  margin: auto;
  margin-bottom: 60px;
  iframe {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 95%;
    margin-bottom: 50px;
  }
}

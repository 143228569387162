.footer {
  padding: 40px 100px 10px 100px;
  color: #fff;
  background-color: rgb(13, 26, 12);
  .footer-top-row {
    width: 40%;
    .footer-top-row-title {
      position: relative;
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      &::before {
        position: absolute;
        left: 0;
        bottom: -5px;
        content: '';
        width: 25%;
        height: 3px;
        background-color: #d24242;
      }
    }
    .footer-top-row-content {
      margin-top: 40px;
      a {
        height: 35px;
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: 500;
        color: #ddd;
        transition: all 0.2s ease-in-out;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:hover {
          color: rgb(100, 100, 100);
          svg {
            filter: invert(0);
            transform: rotate(360deg);
          }
        }
        svg {
          width: 35px;
          height: auto;
          margin-right: 10px;
          transition: all 0.3s ease-in-out;
          transition: transform 0.6s ease-in-out;
        }
      }
    }
  }
  .footer-bottom {
    text-align: center;
    font-size: 18px;
    margin-top: 50px;
    perspective: 100px;
    .impressum-link {
      color: #fff;
      font-size: 12px;
      display: flex;
      justify-content: center;
      color: #fff;
      transition: transform 1s;
      &:hover {
        color: rgb(100, 100, 100);
        transform: translateZ(5px);
      }
    }
    hr {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .select-language {
      margin-left: auto;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 40px 80px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 800px) {
    padding: 40px;
    padding-bottom: 10px;
    .select-language {
      margin: auto;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 30px 30px;
    .footer-top-row {
      width: 100%;
      margin-top: 40px;
      .footer-top-row-content {
        margin-top: 20px;
      }
    }
  }
}

.dropdown {
  position: fixed;
  top: 90px;
  right: 80px;
  width: 20%;
  max-height: 0;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: max-height 0.4s ease;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-top: none;
  &.opened {
    max-height: 190px;
  }

  .dropdown-link {
    padding: 15px 20px;
    font-size: 20px;
    color: #000;
    transition: all 0.2s ease-in-out;
    &.active {
      background-color: rgb(200, 200, 200);
      color: #000;
    }
    &:hover {
      background-color: rgb(200, 200, 200);
      color: #000;
    }
    &:last-of-type {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  @media screen and(max-width: 1200px) {
    right: 40px;
  }
  @media screen and(max-width: 800px) {
    right: 0;
    left: 0;
    width: 100%;
  }
}

.prices {
  margin-top: 50px;
  padding-bottom: 30px;
  .prices-text {
    width: 40%;
    .title {
      margin: initial;
      text-align: initial;
    }
    .subtitle {
      text-align: initial;
      width: 100%;
      margin-top: 40px;
      line-height: 1.4;
    }
  }
  .prices-photo {
    text-align: center;
    font-size: larger;
    width: 35%;
    img {
      cursor: pointer;
    }
    #ReactSimpleImageViewer {
      z-index: 10000000;
      .styles-module_close__2I1sI,
      .styles-module_navigation__1pqAE {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        height: fit-content;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    a {
      margin-left: 10px;
    }
  }

  @media screen and(max-width: 800px) {
    flex-direction: column;
    margin-top: 100px;
    .prices-text,
    .prices-photo {
      width: 90%;
    }
  }
}

.works {
  width: 90%;
  margin: auto;
  padding: 50px 0;
  .alice-carousel {
    width: 95%;
    .alice-carousel__dots {
      display: none;
    }
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      font-size: 70px;
      padding: 0;
      position: absolute;
      top: calc(50% - 47.5px);
    }
    .alice-carousel__prev-btn {
      left: -50px;
    }
    .alice-carousel__next-btn {
      right: -50px;
    }
    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
      padding: 0;
      color: rgb(29, 56, 33);
      transition: color 0.3s ease-in-out;
      &:hover {
        color: rgb(116, 116, 116);
      }
      &.__inactive {
        display: none;
      }
    }
    .item {
      margin: auto;
      width: 95%;
      height: 350px;
      border-radius: 10px;
      background-size: cover;
    }
  }

  @media screen and(max-width: 800px) {
    .alice-carousel {
      .alice-carousel__prev-btn {
        left: -45px;
      }
      .alice-carousel__next-btn {
        right: -45px;
      }
      .alice-carousel__dots {
        display: block;
        margin-top: 20px;
        .alice-carousel__dots-item {
          background-color: rgb(116, 116, 116);
          &.__active {
            background-color: #000;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 30px 0;
    .alice-carousel {
      .alice-carousel__prev-btn,
      .alice-carousel__next-btn {
        font-size: 50px;
      }
      .alice-carousel__prev-btn {
        left: -25px;
      }
      .alice-carousel__next-btn {
        right: -25px;
      }
    }
  }
}
